export const patientCS: any = {
  mainMenu: "Main Navigational Menu Consumer Retina Brazil (PT)",
  footerMenu: "Footer Navigation Menu Consumer Retina Brazil (PT)",
  banner: "Banner - HTML - CR - DTC - Brazil (PT)",
  footer: "Footer - Consumer - Retina Brazil (PT)",
  exitPopup: "Exit Popup Brazil (PT)",
  footerRef: "DTC - Community Resource - Reference - Brazil (PT)",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  topNav: "Top Navigation Menu Retina Brazil (PT)",
  videoCR: "Community Resources Video Section - Retina Brazil (PT)",
  videoPara1: "Community Resources Video Thumbnail Heading - Retina Brazil (PT)",
  allisonvideo: "Community Resources Allison Testimonial - Retina Brazil (PT)",
  siteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  allisonVideoHead: "Allison Testimonial Video heading - Community Resources - Retina Brazil (PT)",
  videoTopPara: "Video Thumbnail Heading HTML - CR - DTC - Retina Brazil (PT)",
  crTitleCard: "Community Resources Title Card - Retina Brazil (PT)",
  titleCard: "Community Resources - Education, Support, & Community HTML - Brazil (PT)",
  anchroLink: "Anchor Link - Community Resources - Retina Brazil (PT)",
  backToTopGTM: "back to top - community resources",
  video1Attrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genetic testing story of cesar - view transcript",
        "className": "gtm-accordion",
        "aria-label": "Mostrar transcrição para A busca de César pelo seu diagnóstico genético"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genetic testing story of cesar - hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Mostrar transcrição para A busca de César pelo seu diagnóstico genético"
      }
    ]
  },
  video23Attrs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genes 101 - view transcript",
        "className": "gtm-accordion",
        "aria-label": "Mostrar transcrição para Princípios de Genética"
      },
      {
        "data-gtm-event-label": "inheritance 101 - view transcript",
        "className": "gtm-accordion",
        "aria-label": "Mostrar transcrição para Hereditariedade Genética"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "genes 101 - hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Mostrar transcrição para Princípios de Genética"
      },
      {
        "data-gtm-event-label": "inheritance 101 - hide transcript",
        "className": "gtm-accordion",
        "aria-label": "Mostrar transcrição para Hereditariedade Genética"
      }
    ]
  },
  footerClassName: "community-resources-footer"
}

