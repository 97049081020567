import styled from "styled-components";
import { device } from "@retina-packages/retina-theme-bootstrap/packages/css/device";

export const CommunityResources = styled.div`
.community-resources {

	.video-container {
		.o-inner {
			@media ${device.desktopsignup} {
				padding-left: 91px;
				padding-right: 91px;
			}
			.video-thumbnail-section-main {
				&:last-child {
					padding-bottom: 0;
				}
				&.o-column--full {
					.video-thumbnail-right {
						.o-paragraph {
							p {
								@media ${device.laptopMedium} {
									font-size: 1.8rem !important;
									line-height: 2.6rem !important;
									text-align: center;
									color: #000000;
								}
								text-align: left;
								&:last-child {
									padding-bottom: 0;
								}
							}
						}
					}
					.o-common-title {
						margin-bottom: 0;
						p {
							margin-bottom: 0;
						}
					}
				}
			}
		}
		&:nth-child(5) {
			.video-thumbnail-section-main {
				.video-section__inner-content__video-btn {
					&.community-video-btn {
						@media ${device.laptopMedium} {
							margin-top: 10px;
						}
						@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
							margin-top: -66px;
						}
						@media ${device.desktopsignup} {
							margin-top: -58px;
						}
					}
				}
				.o-common-title {
					p {
						@media ${device.laptopMedium} {
							max-width: 100%;
						}
					}
				}
			}
		}
	}
	.title-card {
		margin-bottom: 208px;

		@media ${device.desktopsignup} {
			margin-bottom: 0;
		}
		@media ${device.desktopsignup} and ${device.miniDesktop} {
			max-width: 1060px;
			margin-left: auto;
			margin-right: auto;
		}
		.o-inner {
			h2 {
				@media ${device.tablet} and ${device.laptopair2max} {
					margin-bottom: 0;
				}
				@media ${device.desktopsignup} {
					margin-bottom: 0;
				}
			}
			.footer-block {
				.footer-inner-block {
					min-height: 340px;


					@media ${device.laptopMedium} {
						min-height: 300px;
					}
					@media ${device.laptopair2max} {
						min-height: auto;
					}
					@media ${device.ipadLandscapemin} and ${device.ipadair2} {
						min-height: 428px;
					}

					.media {
						a {
							padding: 14px 22px;
							min-height: 95px;
							line-height: 2.3rem;
							font-size: 2rem;

							@media ${device.desktopsignup} {
								display: flex;
								max-width: 384px;
								letter-spacing: normal;
							}
							@media ${device.laptopMedium} {
								font-size: 1.8rem;
								line-height: 2.2rem;
							}
							span {
								color: #00ffd9;
								display: contents;
							}

							&::after {
								margin-top: 0;

								@media ${device.desktopsignup} {
									padding-right: 18px;
								}
								@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
									margin-left: 6px;
								}
							}
						}
					}

					p,h3 {
						max-width: 394px;
						@media ${device.tablet} and ${device.laptopair2max} {
							max-width: 400px;
						}
					}
					.o-common-title,h3 {
						font-size: 2.7rem;
						line-height: 3.8rem;
						color: #8000BE;
						text-align: center;
						font-weight: 700;
						margin: 0;

						@media ${device.laptopMedium} {
							font-size: 2rem;
							padding-bottom: 15px;
							line-height: 2.4rem;
						}
					}

					@media ${device.ipadLandscape} {
						border-radius: 10px;
						padding-top: 28px;
						padding-bottom: 16px;
						width: 100%;
						padding-left: 16px;
						padding-right: 16px;
						margin-left: 0;
						margin-top: 20px;
					}
				}
			}
		}
		.genetic-small-paragraph {
			@media ${device.ipadLandscape} {
				font-size: 1.8rem;
    		line-height: 2.2rem;
				padding-bottom: 8px;
			}
		}
	}

	.community-title-card {
		@media ${device.ipadLandscape} {
			margin-bottom: 0;
		}
		@media ${device.laptopMedium} {
			margin-bottom: 180px;
		}
	}

	.community-resources-main-heading {
		padding: 75px 48px 40px 48px;

		@media ${device.laptopMedium} {
			padding: 28px 0 10px 0;
		}

		@media ${device.laptopPros} {
			padding-left: 2.7rem;
			padding-right: 2.7rem;
		}
		p,h2 {
			font-size: 4.2rem;
			line-height: 5rem;
			color: #000000;
			text-align: center;
			font-weight: 700;
			margin: auto;

			strong {
				font-weight: 700;
			}

			@media ${device.laptopMedium} {
				font-size: 2.5rem;
    			line-height: 3rem;

				strong {
					font-weight: 700;
				}
			}
		}
	}

	.resource-anchor-links-block {
		padding: 20px 48px 60px 48px;

		@media ${device.largeS} {
			padding: 20px 70px 60px 70px;
		}

		@media ${device.laptopPros} {
			padding-left: 2.7rem;
			padding-right: 2.7rem;
			padding-bottom: 20px;
		}
		@media ${device.laptopMedium} {
			display: none;
		}
		.resource-links {
			display: flex;
			justify-content: space-around;
			width: 80%;
    	margin: auto;

			@media ${device.ipadLandscapemin} and ${device.minimumDeskEnd} {
				width: 85%;
			}
			@media ${device.smalldeskstrt} and ${device.smalldeskmid} {
				width: 85%;
			}
			@media ${device.desktopsignup} {
				width: 85%;
			}
			@media ${device.largeS} {
				width: 100%;
			}
			@media ${device.laptopMedium} {
				width: 100%;
			}

			a {
				background-color: #f7f7f7;
				position: relative;
				padding: 10px 20px;
				text-decoration: none;
				font-weight: 500;
				color: #8000be;
				text-align: center;
				font-size: 2.2rem;
				line-height: 3.2rem;

				&:after {
					content: '';
					width: 16px;
					height: 16px;
					display: inline-block;
					margin-left: 10px;
					position: relative;
					top: -4px;
					vertical-align: middle;
					background-size: 15px;
				}

				@media ${device.desktopsignup} {
					min-width: 260px;
				}
				@media ${device.largeS} {
					min-width: 336px;
				}
				@media ${device.laptopair2max} {
					min-width: 180px;
					font-size: 1.8rem;
					line-height: 2.7rem;
					padding: 10px 20px;
				}
				@media ${device.laptopPros} {
					min-width: unset;
    			padding: 10px 10px;
					font-size: 1.2rem;
    			line-height: 2.2rem;
				}
				@media ${device.smallMobMax} {
					font-size: 1rem;
    			line-height: 2rem;
				}
			}
		}
	}

	#be-inspired,
	#historia-real {
		font-size: 4.2rem;
    font-weight: 700;
    line-height: 5rem;
    color: #000000;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 56px;
		margin-bottom: 0;

		@media ${device.laptopMedium} {
			padding-top: 28px;
			padding-bottom: 10px;
			margin-bottom: 0;
			font-size: 2.5rem;
    	line-height: 3rem;
		}
		@media ${device.laptopPros} {
			padding-left: 2.7rem;
			padding-right: 2.7rem;
		}
	}

	.logo-section {
		position: relative;
		padding-bottom: 88px;

		&:before {
			content: "";
			background-color: #00FFD9;
			position: absolute;
			top: 120px;
			left: 0;
			height: calc(100% - 240px);
			width: 100%;
		}

		.connect--cover-bg-wrapper {
			background-color: #F7F7F7;
			box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
   	 	position: relative;
			padding-bottom: 75px;

			&:before {
				content: "";
				background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				position: absolute;
				top: 0;
				width: 100%;
				height: 22px;
				left: 0;
				right: 0;
			}

			@media ${device.laptopMedium} {
				padding-bottom: 0;

			}
			.o-inner {
				padding-left: 100px;
    		padding-right: 100px;

				@media ${device.laptopMedium} {
					padding-left: 0;
					padding-right: 0;
				}

				.btm-logo-block {
					@media ${device.laptopMedium} {
						padding-bottom: 8.8rem;
					}
				}

				.o-internal--h2 {
					padding: 88px 48px 40px 48px;

					@media ${device.laptopMedium} {
						padding: 35px 42px 32px 42px;
					}

					.logo-section-find {
						margin-bottom: 0;
					}
				}
			}
		}

		.logo-block {
			ul {
				display: flex;
				justify-content: center;
				width: 100% !important;

				@media ${device.laptopMedium} {
					padding-bottom: 88px;
				}
				li {
					height: 187px;
					line-height: 187px;
					width: 25% !important;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;

					@media ${device.laptopair2max} {
  					border-bottom: none;
					}
					@media ${device.laptopMedium} {
						width: 50% !important;
						padding: 15px;
						height: 100px;
						line-height: 25px;
					}
          @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax}{
            width: 50% !important;
          }
					&:nth-child(1),&:nth-child(2) {
						.extlink {
							.media--image {
								@media ${device.desktopsignup} {
									height: 100px;
								}
								@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
  								height: 87px;
								}
								@media ${device.laptop} and ${device.minimumDeskEnd} {
									height: 100px;
								}
							}
						}
					}
					&:nth-child(2) {
						.extlink {
							.media--image {
								@media ${device.desktopsignup} {
									height: auto;
								}
                @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
                  margin-top: -60px;
                }
							}
						}
					}

					.extlink {
						.media--image {
							vertical-align: middle;
							text-align: center;
							margin: auto;
							object-fit: contain;

							@media ${device.laptopMedium} {
								max-height: 75px;
								width: 100%;
							}

							@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
								width: 100%;
								padding: 0 10px;
							}
						}
					}
					img {
						vertical-align: middle;
						text-align: center;
						margin: auto;
						object-fit: contain;
  					mix-blend-mode: multiply;

						@media ${device.laptopMedium} {
							max-height: 75px;
							width: 100%;
						}

						@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
							width: 100%;
							padding: 0 10px;
						}
					}
				}
			}
		}

		@media ${device.ipadLandscape} {
			padding-top: 43px;
			padding-bottom: 30px;
		}
		.p {
			@media ${device.ipadLandscape} {
				padding: 19px 4px 21px 4px;
			}
		}
		.o-inner {
			.btm-logo-title {
				@media ${device.laptopMedium} {
					padding-top: 0;
				}
				.log-heading-style {
					@media ${device.laptopMedium} {
						font-size: 2rem;
						line-height: 2.4rem;
					}
				}
			}
		}
		.o-text-wrap {
			.logo-section-edu {
				@media ${device.laptopMedium} {
					width: 100%;
					padding: 0 42px 8px 42px;
				}
			}
			.logo-section-find {
				@media ${device.laptopMedium} {
					margin-bottom: 0;
				}
			}
			.ext-notice {
				line-height: 2.4rem;

				@media ${device.laptopMedium} {
					font-size: 14px;
					line-height: 2.1rem !important;
					padding: 0 42px 32px 42px;
				}
				@media ${device.ipadLandscape} {
					font-size: 14px;
				}
			}
		}
		.o-inner {
			.o-header--h3 {
				font-family: 'Gotham', Arial, Helvetica, sans-serif;
				.logo-section-info {
					strong {
						@media ${device.laptopMedium} {
							font-weight: normal;
							padding-bottom: 24px;
							width: 70%;
							margin: auto;
							font-size: 2rem;
							line-height: 2.4rem;
							padding: 48px 0 24px;
						}
					}
				}
			}
			.btm-logo-block {
				ul {
					li {
						img {
							vertical-align: middle;
							text-align: center;
							margin: auto;
							max-width: 100%;

							@media ${device.laptopMedium} {
								width: 143.172px;
								max-height: 75px;
							}
						}
						@media ${device.laptopMedium} {
							width: 50%;
							padding: 15px;
							height: 100px;
							line-height: 25px;
						}
					}
				}
			}
		}

		.o-image-wrapper-main {
			@media ${device.laptopMedium} {
				padding: 0 1.5rem;
			}
		}
	}
}
	.footer {
		.footercontainer {
			/* margin-top: 63px;
			padding-top: 0;
			padding-bottom: 48px;
			@media ${device.laptopMedium} {
				padding-bottom: 18px;
			}
			@media ${device.desktopsignup} {
				margin-top: 0;
			} */
			footer {
				/* @media ${device.mobileStart} and ${device.tabletMax} {
					padding: 0 20px;
				} */
				.col-lg-4 {
					.gatsby-image-wrapper {
						@media ${device.laptopMedium} {
							margin-top: 0;
						}
					}
				}
				&>.row {
					padding: 10.5rem 0 0 0 !important;

					@media ${device.miniDesktop} {
						padding: 90px 0 0;
					}
					@media ${device.laptopMedium} {
						padding: 6rem 0 0 0 !important;
					}
				}
			}
		}
	}
	footer {
		.row {
			padding-top: 0;
		}
		.left-footer {
			.navbar {
				margin-bottom: 0;
				padding-bottom: 0;
				padding-top: 0;
			}
			.o-column--full {
				padding-top: 31px;
				margin-bottom: 0;

				&:last-child {
					padding-top: 19px;
				}
			}
			.o-copyright {
				margin-bottom: 0;
				.o-column--full {
					padding-top: 31px;
					@media ${device.laptopMedium} {
						padding-top: 15px;
					}
				}
			}
			.o-copyright {
				.o-column--full {
					margin-bottom: 0;
					line-height: 15px;
					@media ${device.laptopMedium} {
						line-height: 2rem;
					}
				}
			}

			.FooterText1 {
				margin-bottom: 0;
				.copyright-footer {
					padding-top: 31px;
					@media ${device.laptopMedium} {
						padding-top: 15px;
					}
					margin-bottom: 0;
					a {
						line-height: 2rem;
					}
				}
				.extlink {
					color: #00FFD9;
				}
			}
		}
	}

	.pagewrapper {
		&:before {
			height: calc(100% + 50px);

			@media ${device.laptop} and ${device.ipadair2} {
				height: calc(100% - 0px);
			}
		}
	}
`;

export const MobileBanner = styled.div`
	position: relative;

	&:after {
		@media ${device.laptopMedium} {
			content: "";
			width: 20px;
			height: calc(100% - 60px);
			position: absolute;
			left: 0;
			top: 10px;
			z-index: 2;
			overflow: hidden;
			background-repeat: no-repeat;
		}
	}
	.o-column--full {
    width: 100%;
	}

	.internal-banner {
		position: relative;

		&:after {
			content: "";
			background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
			position: absolute;
			top: calc(100% - 32px);
			width: 100%;
			height: 32px;
			left: 0;
			right: 0;

			@media ${device.laptopMedium} {
				height: 24px;
				top: calc(100% - 24px);
			}
		}

		.internal-banner__image {
			img {
				object-fit: cover;
				width: 100%;
				object-position: center center;
				height: 100%;
				display: block;

				@media ${device.laptopPros} {
					height: 100%;
					object-position: 93% 0%;
				}

				@media ${device.ipadLandscape} {
					height: 100%;
					object-position: 90% 0%;
				}
			}
		}

		.internal-banner__contant {
			position: absolute;
			left: 0;
			width: 100%;
			top: calc(50% - 18px);
			transform: translateY(-50%);

			@media ${device.laptopMedium} {
				top: 28px;
				transform: translateY(0);
			}

			.o-container {
				max-width: 1208px;
				margin: 0 auto;
				padding: 0 2rem;

				@media ${device.laptopMedium} {
					padding: 0 2.7rem;
				}

				@media ${device.laptopMedium} {
					max-width: 718px;
					padding-top: 43px;
					padding-bottom: 30px;
				}

				.internal-banner__large-title,
				h1 {
					font-size: 5.3rem;
					line-height: 5.9rem;
					color: #fff;
					text-transform: uppercase;
					padding: 0 !important;
					max-width: 70%;
					margin: 0;
					font-weight: 700;

					@media ${device.laptopMedium} {
						text-align: center;
						width: 100%;
						margin: auto;
						max-width: 100%;
						font-size: 3.2rem;
						line-height: 3.8rem;
					}

					span {
						color: #00FFD9;
					}
				}
			}
		}
	}
`;
export const RepWrapper  = styled.div`
.o-paragraph{
    margin-bottom: 40px;
}
	.video-thumbnail-section {
		.video-thumbnail-section-container {
			&:first-child {
				.thumbnail-video-section {
					padding: 28px 0 0 0;
					@media ${device.laptop} {
						padding-top: 75px;
					}
					p {
						padding-bottom: 32px;
						position: relative;
						font-size: 2.2rem;
    				line-height: 2.6rem;
						color: #000000;
						text-align: center;
						font-weight: 500;
						margin: auto;
						margin-bottom: 32px;

						@media ${device.ipadLandscapemin} {
							font-size: 3.4rem;
							line-height: 4.7rem;
							color: #000000;
							text-align: center;
							font-weight: 500;
							margin: auto;
							padding: 0;
						}

						&:after {
							content: "";
							background-color: #8000BE;
							position: absolute;
							bottom: 0;
							width: 74%;
							height: 1px;
							left: 0;
							right: 0;
							margin: auto;
							display: none;

							@media ${device.laptopMedium} {
								display: block;
							}
						}
					}
				}
			}

			&:last-child {
				padding-bottom: 10px;
				.thumbnail-video-section {
					p,h2 {
						padding: 0 0 0 0;
						font-size: 2.5rem;
    				line-height: 3rem;
						color: #000000;
    				text-align: center;
						margin-bottom: 0;

						@media ${device.laptopPros} {
							width: 100%;
						}

						@media ${device.ipadLandscapemin} {
						font-size: 4.2rem;
						font-weight: 700;
						line-height: 5rem;
						color: #000000;
						text-align: center;
						padding-top: 40px;
						padding-bottom: 56px;
						}
					}
				}
			}
		}
	}
`;

export const FooterImageGroup = styled.div`
	.o-inner {
		padding-left: 84px;
		padding-right: 84px;

		@media ${device.ipadLandscape} {
			padding-left: 0;
    	padding-right: 0;
		}
		.o-internal--h2 {
			font-size: 4.2rem;
			font-weight: 700;
			line-height: 5rem;
			color: #000000;
			text-align: center;
			margin-bottom: 0;

			@media ${device.ipadLandscape} {
				font-size: 2.5rem;
    		line-height: 3rem;
			}
		}
		.o-other-texts {
			p {
				color: #000000;
				text-align: center;
				max-width: 768px;
				margin: auto;
				padding: 0 0 20px 0;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;

				@media ${device.ipadLandscape} {
					padding: 0 4px 21px 4px;
					font-size: 1.8rem;
    			line-height: 2.2rem;
				}
				@media ${device.laptopMedium} {
					padding: 0 42px 32px 42px;
				}
			}
		}

		.o-text-wrap {
			p {
				max-width: 100%;
				text-align: center;
				strong {
					font-size: 4.2rem;
					font-weight: 700;
					line-height: 5rem;
					color: #000000;
					text-align: center;
				}
			}
			&:nth-child(2) {
				p {
					color: #000000;
					text-align: center;
					margin: auto;
					padding: 0;
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;

					@media ${device.laptopMedium} {
						font-size: 25px !important;
    				line-height: 3rem !important;
					}
					strong {
						@media ${device.laptopMedium} {
							font-size: 25px !important;
							line-height: 3rem !important;
						}
					}
				}
			}
			&:nth-child(3) {
				p {
					color: #000000;
					text-align: center;
					max-width: 768px;
					margin: auto;
					padding: 40px 0 44px 0;
					font-family: "Gotham", Arial, Helvetica, sans-serif;
					font-size: 22px;
					font-weight: 500;

					@media ${device.laptopMedium} {
						padding: 19px 4px 21px 4px;
						font-size: 1.8rem;
						line-height: 2.2rem;
						font-weight: 500;
						color: #000000;

					}
				}
			}
			&:nth-child(4) {
				p {
					font-weight: 700;
					color: #8000BE;
					text-align: center;
					padding-bottom: 42px;
					font-size: 2.7rem;
					line-height: 3.8rem;

					strong {
						font-weight: 700;
						color: #8000BE;
						text-align: center;
						padding-bottom: 42px;
						font-size: 2.7rem;
						line-height: 3.8rem;
					}
				}
				@media ${device.laptopMedium} {
					padding-bottom: 32px;
					margin: auto;
					font-size: 2rem;
					line-height: 2.4rem;
					font-weight: 700;
					color: #8000BE;
					text-align: center;
				}
				strong {
					@media ${device.laptopMedium} {
						padding-bottom: 24px;
						width: 70%;
						margin: auto;
						font-size: 2rem;
						line-height: 2.4rem;
						font-weight: 700;
						color: #8000BE;
						text-align: center;
					}
				}
			}
		}
		ul {
    		justify-content: center;
				list-style: none;
				width: 68%;
				margin: -6px auto;
				padding: 0;

				@media ${device.laptopMedium} {
					width: 100%;
					display: table;
					margin: 0 auto;
				}

			li {
				height: 187px;
				line-height: 187px;
				text-align: center;
				border-right: 1px solid #707070;
				border-bottom: 1px solid #707070;
				align-items: center;
				justify-content: center;
				float: left;
				width: 33%;

				&:last-child {
					border-right: none;

					img {
						max-width: 100%;
					}
				}

				img {
					@media ${device.ipadLandscape} {
						width: 70%;
					}

					@media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
						width: 70%;
					}
				}
			}
		}
		.second-heading {
			padding-bottom: 42px;
			margin-bottom: 0;
			text-align: center;

			.log-heading-style {
				font-size: 27px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.41;
				letter-spacing: normal;
				text-align: center;
				color: #8000be;
			}

			@media ${device.laptopMedium} {
				padding: 0;
			}

			p {
				text-align: center;
				margin: 0;

				strong{
					font-weight: 700;
					color: #8000BE;
					text-align: center;
					padding-bottom: 42px;
					font-size: 2.7rem;
					line-height: 3.8rem;

					@media ${device.laptopMedium} {
						padding-bottom: 0;
						width: 70%;
						margin: auto;
						font-size: 2.7rem;
    				line-height: 2.8rem;
					}
				}
			}
		}
		.btm-logo-block {
			ul {
				margin: 0 auto;
				display: flex;
			}
		}
	}
`;

export const GenTestBenefitWrapperMain = styled.div`
  .community-resources {
    .video-container {
      .o-inner {
        .video-thumbnail-section-main {
          .video-thumbnail-right {
            .o-header {
              p {
                strong {
                  font-weight: 600;
                }
              }
            }

            .o-paragraph {
              p {
								font-size: 1.8rem;
								line-height: 2.6rem;
								padding: 12px 0 21px 0;
								text-align: center;
								max-width: 100%;

								@media ${device.ipadLandscapemin} {
									color: #000000;
									padding-top: 27px;
									font-size: 2.2rem;
									font-weight: 500;
									line-height: 3.2rem;
									margin-bottom: 0;
								}
              }
            }
          }
        }
      }
    }
  }

  .generic-testing-benefits {
    .early-testing-benefita-container {
      .testing-list-bg {
        .o-container {
          .bg-color-component {
            .listing-box {
              .o-top-space {
                .genetic-points-paragraphs {
                  ul {
                    li {
                      @media ${device.laptopMedium} {
                        padding: 6px 0 6px 24px;
                        font-size: 1.8rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .genetic-left-img-points {
      .left-image-points {
        .listing-btm-section {
          ul {
            &.right-description {
              li {
                &:nth-child(2) {
                  margin-left: 25px;

                  &:before {
                    border-radius: 50%;
                  }
                }

                &:before {
                  @media ${device.tablet} and ${device.laptopMedium} {
                    top: 11px;
                  }

                  @media ${device.laptopPros} {
                    top: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .o-container {
    padding: 0 2rem;

    @media ${device.laptopPros} {
      padding: 0 2.7rem;
    }

    @media ${device.ipadLandscape} {
      max-width: 718px;
    }
  }

  .o-backtotop-btn {
    @media ${device.ipadLandscape} {
      top: -100px;
    }
  }

  .logo-section {
    .o-inner {
        p{
          text-align: center;
          padding-bottom:42px;
          strong{
            color: rgb(128, 0, 190);
            font-size: 2rem;
            line-height: 2.4rem;
            text-align: center;
            @media ${device.ipadLandscapemin} {
              font-size: 2.7rem;
              line-height: 3.8rem;
            }
          }
        }

      .o-text-wrap {
        text-align: center;
        padding-bottom: 42px;
        strong{
          color: rgb(128, 0, 190);
          font-size: 2.7rem;
          line-height: 3.8rem;
        }
        &:nth-child(2) {
          p {
            @media ${device.laptopPros} {
              padding: 0;
            }
          }
        }

        &:nth-child(4) {
          p {
            @media ${device.laptopMedium} {
              font-size: 2rem;
              line-height: 2.4rem;
              padding-bottom: 0;
              margin: 0;
            }

            strong {
              @media ${device.laptopMedium} {
                font-size: 2rem;
                line-height: 2.4rem;
                padding-bottom: 0;
              }
            }
          }
        }
      }

      .logo-block {
        ul {
          @media ${device.laptopMedium} {
            width: 100% !important;
          }

          li {
            @media ${device.mobileMedium} {
              height: 100px;
              line-height: 100px;
            }
          }
        }
      }

      .btm-logo-block {
        @media ${device.laptopMedium} {
          padding-bottom: 80px;
        }
      }

      .btm-logo-title {
        @media ${device.laptopMedium} {
          padding: 48px 0 24px;
          width: 70%;
          margin: auto;
          font-size: 2rem;
          line-height: 2.4rem;
        }
      }
    }
  }

  .title-card {
    background-color: #300064;
    margin:  120px 0;
    padding: 87px 100px;

    @media ${device.ipadLandscape} {
      margin: 40px 0;
      padding: 44px 43px 48px;
    }

    .o-inner {
      padding: 0;

      h2 {
        color: #fff;
        text-align: center;
        padding-bottom: 4rem;
        font-size: 4.2rem;
        font-weight: 600;
        line-height: 5rem;

        @media ${device.ipadLandscape} {
          padding-bottom: 19px;
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }

      .footer-block {
        padding: 0;
        margin: 0;
        display: flex;
        width: 100%;

        @media ${device.ipadLandscape} {
          display: block;
        }

        .footer-inner-block {
          text-align: center;
          position: relative;
          border-radius: 5px;
          background-color: #fff;
          box-shadow: 0px 2px 4px rgb(17 17 17 / 16%);
          padding: 38px 24px 120px;
          margin: 0 16px;
          width: calc(50% - 26px);

          @media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
            padding: 38px 24px 133px;
          }

          @media ${device.ipadLandscape} {
            border-radius: 10px;
            padding-top: 28px;
            padding-bottom: 16px;
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            margin-top: 20px;
            width: 100%;
          }

          &:before {
            content: "";
            background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
            width: 100%;
            height: 16px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px 5px 0 0;

            @media ${device.ipadLandscape} {
              border-radius: 10px 10px 0 0;
            }
          }

          &:first-child {
            margin-left: 0;
          }

          &:nth-child(2) {
            &:before {
              background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
            }

            @media ${device.ipadLandscape} {
              margin-left: 0;
              margin-top: 15px;
            }
          }

          p,.title-card-heading,.o-common-title {
            max-width: 400px;
            font-size: 2.7rem;
            line-height: 3.8rem;
            color: #8000BE;
            text-align: center;
            font-weight: 700;
            margin: 0;

            @media ${device.ipadLandscape} {
              font-size: 2rem;
              line-height: 2.4rem;
              padding-bottom: 15px;
            }
          }

          a {
            left: 23px;
            right: 23px;
            bottom: 30px;
            padding: 12px 23px 12px 23px;
            display: table;
            align-items: center;
            box-shadow: 0px 3px 4px #d6d6d6;
            position: absolute;
            width: 90%;
            text-align: center;
            margin: 48px auto 0;
            background: #8000BE;
            color: #fff;
            border: 1px solid #fff;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 2.4rem;
            text-decoration: none;
            z-index: 1;
            vertical-align: top;
            overflow: hidden;
            letter-spacing: 0;
            border-radius: 6px;
            cursor: pointer;

            @media ${device.ipadLandscape} {
              display: block;
              position: initial;
              font-size: 1.8rem;
              line-height: 2.6rem;
              margin: 15px auto 0;
              width: 100%;
            }

            &:hover {
              background: #fff;
              color: #8000BE;
              border-color: #fff;
              font-weight: 700;
              box-shadow: 0px 3px 4px #d6d6d6;
            }

            &:after {
              content: '';
              display: inline-block;
              height: 20px;
              width: 16px;

              @media ${device.ipadLandscape} {
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }

  .clinical-trials-banner{
    .internal-banner{
      @media ${device.laptopMedium}{
        height: 500px;
        width: 100%;
      }
			div.internal-banner__image {
				@media ${device.laptopMedium}{
					height: 100%;
				}
			}
      .gatsby-image-wrapper-constrained{
        width:100%;

        @media ${device.laptopMedium}{
          height: 500px;
          object-position: 90% 0%;
        }
        @media ${device.desktopsignup}{
          margin-top: -2px;
        }
        @media ${device.largeDesktop}{
          height: 380px;
        }
        @media ${device.extraLargeDesktop}{
          height: 410px;
        }
      }
      &:after{
        @media ${device.largeDesktop}{
         top: 94%;
        }
      }
      .internal-banner__contant{
        .o-container{
          @media ${device.laptopPros}{
            padding: 1rem 3rem;
          }
          h1{

            @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
              margin: 15px 0;
              width: 100% !important;
            }
            @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
              margin-left: 3rem;
            }

            @media ${device.extraLargeDesktop}{
              margin-top: 5rem;
            }
          }
        }
      }
      .internal-banner__image{
        @media ${device.laptopMedium}{
          height: 470px;
        }
        .desktop-image{
          height: 100%;
            .media--image{
              height: 100%;
            }
          img{
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: 90% 0%;

            @media ${device.laptopPros}{
              display: none;
            }
            @media ${device.ipadLandscapemin} {
              width: 100%;
            }
          }
        }
        .mobile-image{
          .media--image{
            height: 100%;
          }
          img{
            display: none;
            @media ${device.laptopPros}{
              display: block;
              object-fit: cover;
              width: 100%;
              height: 100%;
              object-position: center !important;
            }
          }
        }
      }
    }
  }

  .footer{
    .o-backtotop-btn{
      @media ${device.laptopMedium}{
        background-color: #300064;;
      }
      .dtp-arrow-down{
            &:before{
              @media ${device.desktopmenu}{
                width: 30px;
                height: 30px;
                margin: 12px auto;
              }
              @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                width: 29px;
                height: 17px;
                margin: 17px auto;
              }
              @media ${device.desktopsignup}{
                width: 29px;
                height: 15px;
                margin: 14px auto;
              }
            }
      }
    }
  }
`;
